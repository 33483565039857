import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
// import { Icon } from 'react-icons-kit';
// import { linkedin } from 'react-icons-kit/fa/linkedin';
// import { facebook } from 'react-icons-kit/fa/facebook';
// import { instagram } from 'react-icons-kit/fa/instagram';
// import { productHunt } from 'react-icons-kit/fa/productHunt';
// import { envelope } from 'react-icons-kit/fa/envelope';
// import { twitter } from 'react-icons-kit/fa/twitter';
// import { github } from 'react-icons-kit/fa/github';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, {
  List,
  ListItem,
  CopyrightText,
  // SocialList,
  SelectWrapper,
} from './footer.style';

import LogoImage from 'common/src/assets/image/logos/SVG/logo-blue.svg';

const Footer = ({ row, col, colOne, colTwo }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        menuWidgets {
          id
          title
          menu {
            id
            link
            text
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container width="1660px">
        <Box className="row copyright" {...row}>
          <CopyrightText>
            <Link to="/" className="logo">
              <Image src={LogoImage} alt="Remote Mentors logo" />
            </Link>
            <Link to="/tos" className="tos-link">
              Terms & Privacy
            </Link>
            <Text className="text" content="© 2020 All Rights Reserved" />
          </CopyrightText>
          {/* <a
            aria-label="go to mail"
            href="mailto:barefoot@remotementors.io&subject=RemoteMentors.io&body=Hi"
            className="mail"
          >
            barefoot@remotementors.io
          </a> */}
        </Box>
        {/* End of copyright row */}
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '30%'],
    mt: [0, '13px', '0'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '70%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '33.33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default Footer;

import { ResetCSS } from 'common/src/assets/css/style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { charityTheme } from 'common/src/theme/charity';
import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import SEO from '../components/seo';
import BannerSection from '../containers/Charity/BannerSection';
import BenefitsBlock from '../containers/Charity/BenefitsBlock';
import CallToActionSection from '../containers/Charity/CallToActionSection';
import {
  CharityWrapper,
  ContentWrapper,
  GlobalStyle,
} from '../containers/Charity/charity.style';
import DrawerSection from '../containers/Charity/DrawerSection';
import FaqSection from '../containers/Charity/FaqSection';
import Footer from '../containers/Charity/Footer';
import MilestoneBlock from '../containers/Charity/MilestoneBlock';
import MockupSection from '../containers/Charity/MockupSection';
import Navbar from '../containers/Charity/Navbar';
import NumbersBlock from '../containers/Charity/NumbersBlock';
import PortraitsSlider from '../containers/Charity/PortraitsSlider';
import ProblemBlock from '../containers/Charity/ProblemBlock';
import QuestionsListSection from '../containers/Charity/QuestionsListSection';
import Testimonials from '../containers/Charity/Testimonials';
import { loadStripe } from '@stripe/stripe-js';
// import Pricing from '../containers/Charity/Pricing';
import JoinBeta from '../containers/Charity/JoinBeta';

// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a Layout component.

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

export default () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <SEO
          url="http://remotementors.io"
          app_id="834596404033480"
          title="🟣 Remote Mentors - Remote Work School"
          description="We are on a mission to facilitate Remote Work around the globe by sharing experiences and best practices from remote work veterans and nomad elite."
          // meta={[
          //   {title:"Remote Mentors | Level up your remote work game and work from anywhere"},
          //   { description: "Social Video Learning for badass remote worker : learn from more than 45 remote work veterans and nomad elite and level up your remote work game" },
          //   {charset: "utf-8"},
          //   {'msapplication-TileColor': "#ffffff"},
          //   {'msapplication-TileImage': "images/icons/ms-icon-144x144.png"},
          //   {'theme-color': "#ffffff"},
          //   {viewport: "width=device-width, initial-scale=1"}
          // ]}
          keywords={[
            'remote work',
            'digital nomads',
            'future of work',
            'wofa',
            'work from anywhere',
            'bali',
            'sundesk',
            'dojo',
            'outpost',
          ]}
        />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ContentWrapper>
            <BannerSection />
            <MockupSection />
            <NumbersBlock />
            <ProblemBlock />
            <BenefitsBlock />
            <QuestionsListSection />
            <PortraitsSlider />
            <MilestoneBlock />
            <Testimonials />
            <FaqSection />
            <CallToActionSection />
            <JoinBeta />
            {/* <Pricing /> */}
            {/* <RiskReversalBlock /> */}
            {/* <FeatureSection /> */}
            {/* <HumanityBlock /> */}
            {/* <BranchSection /> Coworkings */}
            {/* <WorkSection />
            <PromotionBlock /> */}
            {/* <MapSection /> */}
            {/* <FundraiserSection /> */}
            {/* <BlogSection /> */}
            {/* <ClientBlock /> Sponsors */}
            {/* <DonateSection /> CTA */}
          </ContentWrapper>
          <Footer />
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import Image from 'gatsby-image';

import BlockWrapper, {
  ContentWrapper,
  ImageArea,
} from './joinBeta.style';

import {
  CallToActionButton,
} from '../CallToActionSection/callToActionSection.style';

const PortraitsSlider = ({ row, col }) => {
  const glideOptions = {
    type: 'carousel',
    perView: 6,
    gap: 15,
    autoplay: 1500,
    hoverpause: true,
    animationDuration: 2500,
    animationTimingFunc: 'ease-in-out',
    // rewind: true,
    // duration: 9,
    breakpoints: {
      300: {
        perView: 2,
        animationDuration: 1500,
        bullets: false,
      },
      600: {
        perView: 2,
        animationDuration: 1500,
        bullets: false,
      },
      1000: {
        perView: 5,
        animationDuration: 2000,
        bullets: false,
      },
      1200: {
        perView: 6,
        animationDuration: 2500,
        bullets: false,
      },
    },
    controls: false,
    bullets: false,
    // bulletWrapperStyle
  };

  const bulletWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
  };

  const data = useStaticQuery(graphql`
    query {
      charityJson {
        portraitsSlides {
          id
          thumb_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  //   const { slogan, title, text, lists, image } = data.charityJson.numberData;

  return (
    <BlockWrapper id="contributors">
      <Container width="1660px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Box id="readyBtn" className="row" {...row}>
                <div>
                <Heading
                  content={`Remote Mentors is launching in days. Click this big button to get  early access`}
                />
                  {/* <a className="badgePH" href="https://www.producthunt.com/upcoming/remote-mentors-1-0/" target="_blank">
                  <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=274755&theme=light" alt="Remote Mentors - Atomic video learning for badass remote workers & companies | Product Hunt" style={{ width: '250px', height: '54px', width: 250, height: 54 }} />
                  </a>          */}
                </div>
                <CallToActionButton>
                  <Link to="https://app.remotementors.io" target="_blank">
                    <span className="text">Get your free access</span>
                  </Link>
                </CallToActionButton>
              </Box>              
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// PortraitsSlider style props
PortraitsSlider.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// PortraitsSlider default style
PortraitsSlider.defaultProps = {
  // PortraitsSlider row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // PortraitsSlider col default style
  col: {
    width: ['100%', '100%', '100%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
    mt: ['100px', '120px', '150px', '200px'],
  },
};

export default PortraitsSlider;

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';

import BlockWrapper, {
  ContentWrapper,
  List,
  Item,
  ImageWrapper,
  CallToActionButton,
} from './callToActionSection.style';

const HumanityBlock = ({ row, col, colHeading, colImage }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        callToActionData {
          image {
            publicURL
          }
          slogan
          title
          text
          buttonLabel
          buttonUrl
          lists {
            id
            text
          }
        }
      }
    }
  `);

  const {
    slogan,
    title,
    text,
    lists,
    image,
    buttonLabel,
    buttonUrl,
  } = data.charityJson.callToActionData;

  return (
    <BlockWrapper id="ready">
      <Container className="ctaContainer" width="1660px">
        <Box className="row" {...row}>
          <Box className="col" {...colImage}>
            <ImageWrapper>
              <Image src={image.publicURL} alt="Charity Landing" />
            </ImageWrapper>
          </Box>
          <Box className="col" {...colHeading}>
            <ContentWrapper>
              <Heading as="h5" content={slogan} />
              <Heading as="h1" content={title} />
            </ContentWrapper>
          </Box>
        </Box>
        {/* <Box id="readyBtn" className="row" {...row}>
          <CallToActionButton>
            <Link to={buttonUrl}>
              <span className="text">{buttonLabel}</span>
            </Link>
            <Image src={heartImage} alt="Charity Landing" />
          </CallToActionButton>
        </Box> */}
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  colHeading: {
    width: ['100%', '80%', '33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },

  colImage: {
    width: ['100%', '100%', '66%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;

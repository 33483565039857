import styled from 'styled-components';
import { themeGet } from 'styled-system';

const BlockWrapper = styled.div`
  width: 100%;

  background: #7440eb08;

  padding: 81px 0 51px;
  @media only screen and (max-width: 1440px) {
    padding: 70px 0 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 50px 0 20px;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 0 20px;
  }

  .row {
    align-items: center;
    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  .col {
    @media only screen and (max-width: 991px) {
      width: 100%;
      max-width: 500px;
    }
  }
`;

export const ImageWrapper = styled.div`
  padding-right: 20px;
  @media only screen and (max-width: 1360px) {
    padding-right: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  padding-left: 37px;

  @media only screen and (max-width: 1360px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  h5 {
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${themeGet('colors.secondary2', '#4517ff')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      // font-size: 14px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  h2 {
    max-width: 600px;
  }

  // p {
  //   font-size: 18px;
  //   line-height: 28px;
  //   margin-bottom: 29px;
  //   color: ${themeGet('colors.text', '#294859')};
  //   @media only screen and (max-width: 1440px) {
  //     font-size: 1rem;
  //     margin-bottom: 27px;
  //   }
  //   @media only screen and (max-width: 1360px) {
  //     font-size: 1rem;
  //   }
  //   @media only screen and (max-width: 991px) {
  //     margin-bottom: 20px;
  //   }
  // }

  .learn__more-btn {
    &::before {
      // background-color: ${themeGet('colors.primary', '#27E8A2')};
    }
  }
`;

export const List = styled.ul`
  margin-bottom: 50px;
  padding-top: 5px;
  @media only screen and (max-width: 1440px) {
    padding-top: 0;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 1360px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
`;

export const Item = styled.li`
  font-size: 20px;
  line-height: 1.6;
  padding: 10px 0;
  color: ${themeGet('colors.heading', '#060F1E')};
  @media only screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 1.6;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 1rem;
    line-height: 24px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 1rem;
    line-height: 20px;
  }
`;

export const ImageArea = styled.div`
  width: 100%;
  // @media only screen and (max-width: 1360px) {
  //   width: calc(100% - 500px);
  // }
  // @media only screen and (max-width: 991px) {
  //   width: calc(100% - 380px);
  // }
  // @media only screen and (max-width: 667px) {
  //   width: 100%;
  //   padding: 70px 0 40px;
  // }

  #portraitSlide {
    padding: 1rem 0 3rem;

    .glide__bullet {
      
      margin: 0 0.5rem;
      opacity: 0.1;
      background-color: ${themeGet('colors.uiBlueLight', '#6b6d96')};
      
      &.glide__bullet--active {
        opacity: 1;
        background-color: ${themeGet('colors.uiBlueLink', '#6b6d96')};
      }
    }

    .glide__slide {
      box-shadow: 0 6px 9px rgba(50, 50, 93, 0.09),
        0 2px 5px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      margin: 1rem 0;

      &:hover{
        cursor: grab;
      }
    }
    .glide__controls {
      display: none;
    }
  }

    .glide__controls {
      position: absolute;
      bottom: 100px;
      right: calc(100% + 50px);
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1440px) {
        bottom: 50px;
      }
      @media only screen and (max-width: 1360px) {
        right: calc(100% + 40px);
      }
      @media only screen and (max-width: 667px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: inherit;
        right: auto;
        bottom: -40px;
      }

      div {
        cursor: pointer;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        min-height: 17px;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        > span {
          display: inline-block;
          width: 30px;
          height: 2px;
          border-radius: 4px;
          background-color: ${themeGet('colors.text', '#294859')};
          position: relative;
          transition: all 0.3s ease;

          &::before,
          &::after {
            content: '';
            display: block;
            width: 14px;
            height: 2px;
            border-radius: 4px;
            background-color: ${themeGet('colors.text', '#294859')};
            position: absolute;
            z-index: 1;
            transition: all 0.3s ease;
          }

          &.next_arrow {
            &::before {
              right: 0;
              transform: rotate(42deg);
              transform-origin: top right;
            }
            &::after {
              right: 0;
              transform: rotate(-42deg);
              transform-origin: 14px 7px;
              width: 11px;
            }
          }

          &.prev_arrow {
            display: none !important; // for V1 lets keep glide for style but only 1 slide so no controls
            &::before {
              left: 0;
              transform: rotate(-42deg);
              transform-origin: top left;
            }
            &::after {
              left: 0;
              transform: rotate(42deg);
              transform-origin: -3px 6px;
              width: 11px;
            }
          }
        }

        &:hover {
          > span {
            width: 100px;
            background-color: ${themeGet('colors.secondary', '#D50032')};
            @media only screen and (max-width: 1440px) {
              width: 70px;
            }

            &::before,
            &::after {
              background-color: ${themeGet('colors.secondary', '#D50032')};
            }
          }
        }
      }
    }
  }
`;

export default BlockWrapper;

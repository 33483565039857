import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import { Button } from '../Navbar/navbar.style';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/src/components/Accordion';
import Container from 'common/src/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import BlockWrapper, {
  ContentWrapper,
  List,
  Item,
  AccordionWrapper,
} from './faqSection.style';

const FaqSection = ({
  // sectionWrapper,
  row,
  // col,
  // secTitleWrapper,
  // secHeading,
  // secText,
  // title,
  // description,
  descriptionStyle,
  // buttonWrapper,
  // button,
  colHeading,
  colAccordion,
}) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        faqData {
          title
          subtitle
          slogan
          text
          questions {
            title
            description
            expand
          }
        }
      }
    }
  `);

  const { slogan, title, text } = data.charityJson.faqData;

  return (
    <BlockWrapper id="faq">
      <Container width="1660px">
        <Box className="row" {...row}>
          <Box className="col" {...colHeading}>
            <ContentWrapper>
              <Heading as="h5" content={slogan} />
              <Heading content={title} />
              <Text content={text} />
              {/* <AnchorLink
                className="smooth_scroll learn__more-btn"
                href="#ready"
              >
                <span className="hyphen" />
                <span className="btn_text">Get your pro pass</span>
              </AnchorLink> */}
            </ContentWrapper>
          </Box>
          <Box className="col" {...colAccordion}>
            <AccordionWrapper>
              <Accordion>
                <>
                  {data.charityJson.faqData.questions.map(
                    (accordionItem, index) => (
                      <AccordionItem
                        className="accordion_item"
                        key={`accordion-${index}`}
                        expanded={accordionItem.expand}
                      >
                        <>
                          <AccordionTitle className="accordion_title">
                            <>
                              <Heading as="h4" content={accordionItem.title} />
                              <IconWrapper>
                                <OpenIcon>
                                  <Icon
                                    className="icon-accordion icon-accordion-minus"
                                    icon={minus}
                                    size={24}
                                  />
                                </OpenIcon>
                                <CloseIcon>
                                  <Icon
                                    className="icon-accordion icon-accordion-plus"
                                    icon={plus}
                                    size={24}
                                  />
                                </CloseIcon>
                              </IconWrapper>
                            </>
                          </AccordionTitle>
                          <AccordionBody className="accordion_body">
                            <Text
                              {...descriptionStyle}
                              content={accordionItem.description}
                            />
                          </AccordionBody>
                        </>
                      </AccordionItem>
                    )
                  )}
                </>
              </Accordion>
            </AccordionWrapper>
            <footer>
              <h3>
                <span>
                  Still have questions? Get in touch with me (Charlo) at{' '}
                </span>
                <a href="mailto:barefoot@remotementors.io&subject=RemoteMentors.io&body=Hi">
                  {' '}
                  barefoot@remotementors.io{' '}
                </a>
                <span> or contact us using the chat on this page</span>
              </h3>
              {/* <AnchorLink
                className="smooth_scroll learn__more-btn"
                href="#ready"
              >
                <span className="hyphen" />
                <span className="btn_text">Get your pro pass</span>
              </AnchorLink> */}
              <Button className="btn_login">
                <Link to="https://app.remotementors.io">
                  <span className="text">Get your free access</span>
                </Link>
              </Button>              
            </footer>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

{
  /* <Box {...secTitleWrapper}>
<Text {...secText} content="FREQUENTLY ASK QUESTION" />
<Heading {...secHeading} content="Want to ask something from us?" />
</Box> */
}

FaqSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

FaqSection.defaultProps = {
  sectionWrapper: {
    id: 'faq_section',
    as: 'section',
    pt: ['60px', '80px', '80px', '80px'],
    pb: ['60px', '80px', '80px', '80px'],
    bg: '#f9fbfd',
  },
  secTitleWrapper: {
    mb: ['55px', '75px'],
  },

  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // alignItems: 'flex-start',
    ml: '-15px',
    mr: '-15px',
  },

  colHeading: {
    width: ['100%', '80%', '33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },

  colAccordion: {
    width: ['100%', '100%', '66%'],
    maxWidth: '800px',
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },

  descriptionStyle: {
    width: 'calc(100% - 2rem)',
    // fontSize: `${3}`,
    // color: 'textColor',
    // lineHeight: '1.75',
    // mb: `${0}`,
  },
};

export default FaqSection;

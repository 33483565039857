import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
// import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import Image from 'gatsby-image';

import BlockWrapper, {
  ContentWrapper,
  TextArea,
  SliderArea,
  HighlightedText,
  List,
  Item,
  ImageWrapper,
} from './benefitsBlock.style';

const BenefitsBlock = ({ row, col }) => {
  const glideOptions = {
    type: 'slider',
    perView: 1,
    // gap: 15,
    autoplay: 2000,
    // hoverpause: true,
    animationDuration: 200,
    animationTimingFunc: 'ease-in-out',
    // rewind: true,
    // duration: 9,
    // breakpoints: {
    //     600: { perView: 1 },
    //     1200: { perView: 7 }
    // },
    controls: false,
    // bullets: true,
    // bulletWrapperStyle
  };

  const bulletWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
  };

  const data = useStaticQuery(graphql`
    query {
      charityJson {
        benefitsData {
          image {
            publicURL
          }
          title
          slogan
          text
          list {
            id
            text
          }
        }
      }
    }
  `);

  const { slogan, title, text, list, image } = data.charityJson.benefitsData;

  return (
    <BlockWrapper id="benefits">
      <Container width="1660px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h5" content={slogan} />
              {/* <Heading
                content={title}
              /> */}
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
      <SliderArea>
        <GlideCarousel carouselSelector="benefitsSlider" options={glideOptions}>
          <Fragment>
            {data.charityJson.benefitsData.list.map(item => (
              <GlideSlide key={item.id}>
                <Heading content={item.text} />
              </GlideSlide>
            ))}
          </Fragment>
        </GlideCarousel>
      </SliderArea>
    </BlockWrapper>
  );
};

// BenefitsBlock style props
BenefitsBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// BenefitsBlock default style
BenefitsBlock.defaultProps = {
  // BenefitsBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // BenefitsBlock col default style
  col: {
    width: ['100%', '100%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default BenefitsBlock;

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';

import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from './mockupSection.style';

const HumanityBlock = ({ row, col, colHeading, colImage }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        mockupData {
          image {
            publicURL
          }
          slogan
          title
          text
          lists {
            id
            text
          }
        }
      }
    }
  `);

  const { title, image } = data.charityJson.mockupData;

  return (
    <BlockWrapper id="mockup">
      <Container width="1660px">
        <Box className="row" {...row}>
          <Box className="col" {...colHeading}>
            <ContentWrapper>
              <Heading as="h1" content={title} />
            </ContentWrapper>
            {/* <ContentWrapper>
              <Heading as="h3" content="Want to know how Remote Mentors can help your company ?" />
              <Link className="learn__more-btn" href="/companies">
                <span className="hyphen" />
                <span className="btn_text">See how Remote Mentors helps remote companies</span>
              </Link>              
            </ContentWrapper> */}
          </Box>
          <Box className="col" {...colImage}>
            <ImageWrapper>
              <Image src={image.publicURL} alt="Charity Landing" />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  colHeading: {
    width: ['100%', '80%', '33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },

  colImage: {
    width: ['100%', '100%', '66%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;

import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Button } from '../Navbar/navbar.style';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import Image from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
  HighlightedText,
} from './bannerSection.style';

const BannerSection = () => {
  const glideOptions = {
    // type: 'carousel',
    perView: 1,
    gap: 0,
  };

  const data = useStaticQuery(graphql`
    query {
      charityJson {
        bannerSlides {
          id
          thumb_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BannerWrapper id="banner">
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#mockup" />
      <ContentWrapper>
        <TextArea>
          {/* <HighlightedText className="highlighted_text">
            <strong>2020 Edition</strong>
            <span>45+ mentors 300+ videos</span>
             <Icon icon={chevronRight} /> 
          </HighlightedText> */}
          <Heading
            as="h1"
            content="Want to level up your remote work game and unlock your freedom?"
          />

          {/* <AnchorLink
            className="smooth_scroll learn__more-btn"
            href="#mockup"
            offset={81}
          >
            <span className="hyphen" />
            <span className="btn_text">I want some details</span>
          </AnchorLink>
          <AnchorLink
            className="smooth_scroll learn__more-btn"
            href="#pricing_section"
            offset={81}
          >
            <span className="hyphen" />
            <span className="btn_text">Show me the prices</span>
          </AnchorLink> */}

          <Button className="btn_login">
            <Link to="https://app.remotementors.io">
              <span className="text">Get your free access</span>
            </Link>
          </Button>          

          {/* BADGE PH */}
          {/* <a className="badgePH" href="https://www.producthunt.com/posts/remote-mentors?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-remote-mentors" target="_blank">
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=274755&theme=dark" alt="Remote Mentors - Atomic video learning for badass remote workers & companies | Product Hunt" style={{ width: '250px', height: '54px', width: 250, height: 54 }} />
          </a>           */}       


          {/* <Link
            className="learn__more-btn"
            href="/companies"
            offset={81}
          >
            <span className="hyphen" />
            <span className="btn_text">How Remote Mentors can help your company</span>
          </Link> */}
        </TextArea>
        <ImageArea>
          <Image
            fluid={
              (data.charityJson.bannerSlides[0].thumb_url !== null) |
              undefined
                ? data.charityJson.bannerSlides[0].thumb_url.childImageSharp
                    .fluid
                : {}
            }
            alt={`Remote mentors banner ${data.charityJson.bannerSlides[0].id}`}
            className="slide_image"
          />          
        </ImageArea>
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;

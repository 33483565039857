import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Container from 'common/src/components/UI/Container';
import { graphql, useStaticQuery, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'react-icons-kit';
import { Button } from '../Navbar/navbar.style';
import { checkCircle } from 'react-icons-kit/feather/checkCircle';
import BlockWrapper, {
  ContentWrapper,
  Item,
  List,
} from './questionsListSection.style';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const QuestionsListSection = ({ row, colHeading, colQuestions }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        QuestionsListSectionData {
          title
          subtitle
          slogan
          text
          questions {
            text
            comment
            highlightTickMark
            highlight
          }
        }
      }
    }
  `);

  const { slogan, title, text } = data.charityJson.QuestionsListSectionData;

  return (
    <BlockWrapper id="questions">
      <Container width="1660px">
        <Box className="row" {...row}>
          <Box className="col" {...colHeading}>
            <ContentWrapper>
              <Heading as="h5" content={slogan} />
              <Heading content={title} />
              <Text content={text} />
              {/* <a
                className="learn__more-btn"
                href="#ready"
              >
                <span className="hyphen" />
                <span className="btn_text">Get your pro pass</span>
              </a> */}
            </ContentWrapper>
          </Box>
          <Box className="col" {...colQuestions}>
            <List className="questionList">
              {data.charityJson.QuestionsListSectionData.questions.map(
                (item, index) => (
                  <div
                    className={item.highlight ? 'highlight' : ''}
                    key={`list_key${index}`}
                  >
                    <Item>
                      <Icon
                        className={
                          item.highlightTickMark
                            ? 'highlightTickMark icon-list'
                            : 'icon-list'
                        }
                        icon={checkCircle}
                        size={28}
                      />
                      <div>
                        <h4>{item.text}</h4>
                        <h4>{item.highlightTickMark}</h4>
                        {/* <p>{item.comment}</p> */}
                      </div>
                    </Item>
                  </div>
                )
              )}
            </List>
            {/* <AnchorLink
              className="smooth_scroll learn__more-btn"
              href="#ready"
            >
              <span className="hyphen" />
              <span className="btn_text">Get your pro pass</span>
            </AnchorLink> */}
            <Button className="btn_login">
              <Link to="https://app.remotementors.io">
                <span className="text">Get your free access</span>
              </Link>
            </Button>             
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

{
  /* <Box {...secTitleWrapper}>
<Text {...secText} content="FREQUENTLY ASK QUESTION" />
<Heading {...secHeading} content="Want to ask something from us?" />
</Box> */
}

QuestionsListSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

QuestionsListSection.defaultProps = {
  sectionWrapper: {
    id: 'faq_section',
    as: 'section',
    pt: ['60px', '80px', '80px', '80px'],
    pb: ['60px', '80px', '80px', '80px'],
    bg: '#f9fbfd',
  },
  secTitleWrapper: {
    mb: ['55px', '75px'],
  },

  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // justifyContent: 'flex-start',
    flexDirection: 'row',
    // alignItems: 'flex-start',
    ml: '-15px',
    mr: '-15px',
  },

  colHeading: {
    width: ['100%', '80%', '33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },

  colQuestions: {
    width: ['100%', '100%', '66%'],
    maxWidth: '800px',
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },

  descriptionStyle: {
    width: 'calc(100% - 2rem)',
    // fontSize: `${3}`,
    // color: 'textColor',
    // lineHeight: '1.75',
    // mb: `${0}`,
  },
};

export default QuestionsListSection;
